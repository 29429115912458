<template>
  <div class="Rankings" v-if="store && data.length > 0">
    <v-card
      tile
      class="d-flex align-center card mb-1 mr-4 ml-4 pa-2"
      v-for="item in data"
      :key="item.id"
      :class="{ own: item.storeName === store.firmName }"
    >
      <v-avatar
        class="mr-2"
        size="50"
        :color="item.place < 4 ? 'primary' : '#ccc'"
      >
        {{ item.place }}
      </v-avatar>
      <h4 style="font-weight: normal; flex: 1 1 auto">
        <span v-if="item.storeName === store.firmName">
          {{ item.storeName }}
        </span>
      </h4>
      <h4>{{ item.score }}</h4>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Rankings",
  data() {
    return {
      data: [],
      store: null,
    };
  },
  created() {
    this.store = this.$store.getters.getStore;
    this.getList();
  },
  methods: {
    getList(keyword) {
      if (!keyword) keyword = "%20";
      this.$store
        .dispatch("getCollection", {
          end: `client/stores/top-scores`,
          payload: null,
        })
        .then((res) => {
          this.data = res;
        });
    },

    formatDate(d) {
      return moment.unix(d).format("hh.mm");
    },
  },
};
</script>
<style scoped>
.own {
  background-color: var(--red);
}
.card {
  border-bottom: 3px solid var(--red);
}
</style>
